import React from 'react';
import './Panel.css';
import PropTypes from 'prop-types';
import ChevronRightIcon from '../../assets/ChevronRight.jsx';

/**
 * @param {object} props - the props of the component
 * @param {string}props.panelHeaderText - panel header title
 * @param {string} props.actionText - a text to specific action
 * @param {Function} props.onClick - a function to navigate onClick
 * @param {JSX.Element} props.children - a function to update the current filter
 * @returns {JSX.Element} Panel component
 */
export default function Panel({ panelHeaderText, actionText = 'View all', onClick, children }) {
  return (
    <div className='panel'>
      <div className='panel-content'>
        <span className='panel-header'>{panelHeaderText}</span>
        <span
          className='view-all'
          onClick={ onClick }
        >
          {actionText} <ChevronRightIcon />
        </span>
      </div>

      {children}
    </div>
  );
}

Panel.propTypes = {
  panelHeaderText: PropTypes.string,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
};
