import React, { useContext } from 'react';
import './InvalidHotDealsPage.css';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { CartContext } from '../../services/context/CartContext.js';
import CheckoutButton from '../../components/CheckoutButton/CheckoutButton.jsx';
import ProductVoucher from '../../components/ProductVoucher/ProductVoucher.jsx';
import AddToCartButtons from '../../components/AddToCart/AddToCartButtons.jsx';

/**
 * @returns {JSX.Element} InvalidHotDealsPage component
 */
export default function InvalidHotDealsPage() {
  const navigateTo = useNavigate();
  const categories = useContext(CategoriesContext);
  const cart = useContext(CartContext);
  const vendors = categories.flatMap(category => category.vendors);
  const vouchers = [];
  for (const vendor of vendors) {
    if (vendor.vouchers) {
      const filteredVouchers = vendor.vouchers.filter(voucher => !voucher.campaign);
      vouchers.push(...filteredVouchers);
      if (vouchers.length >= 4) break;
    }
  }

  const selectedVouchers = vouchers.slice(0, 4);

  return (
    <div className='invalidHotDealPage-header-container'>
      <span className='invalidHotDealPage-header'>This offer is no longer valid</span>
      <div className='invalidHotDealPage-sub-header-container'>
        <span className='invalidHotDealPage-sub-header'>Apologies, but it seems like this offer has expired.</span>
        <span className='invalidHotDealPage-sub-header'>Check out these offers below instead.</span>
      </div>
      <div className='voucher-list'>
        {
          selectedVouchers.map((voucher, index) => (
            <ProductVoucher
              key={ index }
              voucher={ voucher }
              actionItem={ <AddToCartButtons voucher={ voucher } /> }
              descriptionType='buyNow'
            />
          ))
        }
        { cart.length > 0 && <CheckoutButton /> }
      </div>
      <div className='checkout-container'>
        <button
          className='back-button'
          onClick={
            () => {
              navigateTo(ROUTES.HOME);
            }
          }
        >Check out other Vouchers</button>
      </div>
    </div>
  );
}
