import React, { useEffect, useState, useRef, useCallback } from 'react';
import './Carousel.css';
import RadioButton from '../RadioButton/RadioButton.jsx';
import CloseIcon from '../../assets/CloseIcon.jsx';
import PropTypes from 'prop-types';
import useSliding from '../../services/hooks/useSliding.js';
import { captureMessage } from '@sentry/react';
import CarouselItem from '../CarouselItem/CarouselItem.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import Resizer from 'react-image-file-resizer';

const resizeFile = async (url, height, width) => {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      blob,
      height,
      width,
      'WEBP',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'base64',
      height,
      width,
    );
  });
};

/**
 * @param {object} props - the props of the component
 * @param {Array<{ backgroundUrl: string, iconUrl: string, altText: string, actionItem: Element, headerText: string, subText: string }>} props.items - the items on the carousel
 * @param {Function} props.onClose - onClose handler
 * @returns {JSX.Element} Carousels Component
 */
export default function Carousel({ items, onClose }) {
  const [currentItem, setCurrentItem] = useState(0);
  const [animation, setAnimation] = useState(0);
  const [doTransition, setDoTransition] = useState(false);
  const [loading, setLoading] = useState(true);
  const analyticsName = `${pagePrefix}: carousel`;
  const carouselRef = useRef(null);
  const logoRef = useRef(null);

  const imageBannerBase64 = useRef({});
  const imageLogoBase64 = useRef({});

  useEffect(() => {
    const callResizeBannerImages = async () => {
      if (items.length > 0 && Object.keys(imageBannerBase64.current).length === 0) {
        const promises = items.map(async item => {
          await resizeFile(item.backgroundUrl, carouselRef.current.clientHeight, carouselRef.current.clientWidth).then(image => {
            imageBannerBase64.current[item.backgroundUrl] = image;
          }).catch(() => {
            imageBannerBase64.current[item.backgroundUrl] = item.backgroundUrl;
          });
        });
        await Promise.all(promises).finally(() => setLoading(false));
      }
    };

    callResizeBannerImages();
  }, [items]);

  useEffect(() => {
    const callResizeLogoImages = async () => {
      if (items.length > 0 && Object.keys(imageLogoBase64.current).length === 0) {
        const promises = items.map(async item => {
          await resizeFile(item.iconUrl, 64, 64).then(image => {
            imageLogoBase64.current[item.iconUrl] = image;
          }).catch(() => { imageLogoBase64.current[item.iconUrl] = item.iconUrl; });
        });
        await Promise.all(promises).finally(() => setLoading(false));
      }
    };

    callResizeLogoImages();
  }, [items]);

  const slideBackIn = useCallback(direction => {
    setDoTransition(true);
    setAnimation(0); // Slide back in
    setCurrentItem(prevState => prevState + direction);
  }, []);

  const snapToOtherSide = useCallback(direction => {
    setDoTransition(false);
    setAnimation(direction * 100); // Snap to other side
    setTimeout(() => slideBackIn(direction), 50);
  }, [slideBackIn]);

  const moveOut = useCallback(direction => {
    setAnimation(-direction * 100); // Move out to the direction of swipe (left for increase)
    setTimeout(() => snapToOtherSide(direction), 50);
  }, [snapToOtherSide]);

  const changeCarousel = useCallback(direction => {
    if (items.length === 1) return;
    setDoTransition(true);
    setTimeout(() => moveOut(direction), 50);
  }, [
    items.length,
    moveOut,
  ]);

  const { distance, touching, onTouchEnd, onTouchStart, onTouchMove } = useSliding(distance_ => {
    const isLeft = distance_ < 0;
    const direction = isLeft ? 1 : -1;
    changeCarousel(direction);
  }, false);

  const translation = touching ? `${distance / 5}px` : `${animation}vw`;

  const {
    altText,
    actionItem,
    headerText,
    subText,
  } = items.at(currentItem % items.length) ?? {};

  useEffect(() => {
    if (items.length > 0) {
      const interval = setInterval(() => {
        changeCarousel(1);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    items,
    changeCarousel,
  ]);

  return (
    <section className={ loading ? 'carousel v-container empty skeleton-loader' : 'carousel v-container' }>
      <div
        ref={ carouselRef }
        className={ loading ? 'wrapper v-container empty skeleton-loader' : 'wrapper v-container' }
        style={
          {
            backgroundImage: `url(${imageBannerBase64.current[items.at(currentItem % items.length)?.backgroundUrl]})`,
            transform: `translateX(${translation})`,
            transition: doTransition ? 'transform .2s' : '',
          }
        }
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
      >
        {
          (typeof onClose === 'function') ? (
            <button
              className='close'
              onClick={ () => onClose() }
            >
              <CloseIcon />
            </button>
          ) : null
        }

        <img
          ref={ logoRef }
          className={ loading ? 'logo hidden' : 'logo' }
          onError={
            event => {
              captureMessage(`Failed to load image: ${event.currentTarget.src}`, 'warning');
              event.currentTarget.parentElement.style.backgroundImage = `url(${imageBannerBase64.current[items.at(currentItem % items.length)?.backgroundUrl]})`;
              event.currentTarget.src = imageLogoBase64.current[items.at(currentItem % items.length)?.iconUrl];
              window.utag?.view({
                ...defaultAnalyticsVariables,
                page_name: analyticsName,
                event_name: [events.pageView],
                link_id: `${pagePrefix}: error`,
                event_error_name: 'carousel display error on landing page',
                event_error_code: 'N/A',
                event_error_type: 'system error',
              });
            }
          }
          src={ imageLogoBase64.current[items.at(currentItem % items.length)?.iconUrl] }
          alt={ altText }
        />
        <CarouselItem
          headerText={ headerText }
          subText={ subText }
          actionItem={ actionItem }
        />
      </div>
      <ul className='container-h'>
        {
          items
            .map((category, index) => (
              <RadioButton
                key={ index }
                selected={ index === (items.length + (currentItem % items.length)) % items.length }
              />
            ))
        }
      </ul>
    </section>
  );
}

Carousel.propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
};
