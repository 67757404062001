import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import { CampaignContext, UpdateCampaignContext } from '../services/context/CampaignContext.js';
import { UpdateSnackBarContext } from '../services/context/SnackBarContext.js';
import FailIcon from '../assets/FailIcon.jsx';
import HttpCodes from '../services/Constants/HttpCodes.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function CampaignController({ slot }) {
  const [campaignIds, setCampaignIds] = useState(null);
  const bearerToken = useContext(BearerTokenContext);
  const { getCampaignIds } = useApis();
  const setSnackBar = useContext(UpdateSnackBarContext);

  const handleError = response => {
    switch (response?.status) {
      case HttpCodes.BAD_REQUEST: {
        setSnackBar({
          icon: <FailIcon />,
          body: 'Bad Request: The request was malformed or incorrect.',
        });
        break;
      }

      case HttpCodes.INTERNAL_SERVER_ERROR: {
        setSnackBar({
          icon: <FailIcon />,
          body: 'Server Error: Something went wrong on the server side.',
        });
        break;
      }

      default: {
        setSnackBar({
          icon: <FailIcon />,
          body: 'An unexpected error occurred. Please try again later.',
        });
      }
    }
  };

  useEffect(() => {
    if (bearerToken && !campaignIds) {
      getCampaignIds(bearerToken)
        .then(campaignIdResponse => {
          if (campaignIdResponse?.status === 200) {
            setCampaignIds(campaignIdResponse?.data?.campaignIds);
          } else {
            handleError(campaignIdResponse);
          }
        })
        .catch(() => {
          setSnackBar({
            icon: <FailIcon />,
            body: 'Campaign could not be found!',
          });
        });
    }
  }, [
    bearerToken,
    campaignIds,
    getCampaignIds,
    setSnackBar,
  ]);

  return (
    <CampaignContext.Provider value={ campaignIds }>
      <UpdateCampaignContext.Provider value={ setCampaignIds }>
        {slot}
      </UpdateCampaignContext.Provider>
    </CampaignContext.Provider>
  );
}

CampaignController.propTypes = { slot: PropTypes.element };
