import React, { useState, useContext } from 'react';
import './HotDealVouchers.css';
import PropTypes from 'prop-types';
import VouchersDisplay from '../Vouchers/VouchersDisplay.jsx';
import Voucher from '../Voucher/Voucher.jsx';
import Slider from '../Slider/Slider.jsx';
import ProductVoucher from '../ProductVoucher/ProductVoucher.jsx';
import AddToCartButtons from '../AddToCart/AddToCartButtons.jsx';
import { CartContext } from '../../services/context/CartContext.js';
import { CategoriesContext, CategoriesCompleteContext } from '../../services/context/CategoriesContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import CheckoutButton from '../CheckoutButton/CheckoutButton.jsx';
import moment from 'moment/moment.js';

/**
 * @param {object} props - component props
 * @param {Array<string>} [props.campaignIds] - list of campaign Id voucher to filter vouchers
 * @param {number} [props.maxSize] - number of vouchers to display
 * @param {JSX.Element} props.onEmpty - element to show when there are no campaign vouchers
 * @returns {JSX.Element} HotDealVouchers component
 */
export default function HotDealVouchers({ campaignIds = [], maxSize, onEmpty }) {
  const cart = useContext(CartContext);
  const categories = useContext(CategoriesContext);
  const categoriesComplete = useContext(CategoriesCompleteContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  let campaignVouchers = null;

  if (categories && categoriesComplete) {
    const allVouchers = categories.reduce((mergedVendors, category) => [
      ...mergedVendors,
      ...category.vendors,
    ], []).reduce((mergedVouchers, nextVendor) => {
      const vouchersWithVendorInfo = nextVendor.vouchers.map(v => ({
        ...v,
        vendorName: nextVendor.name,
        vendorIconUrl: nextVendor.iconUrl,
      }));
      return [
        ...mergedVouchers,
        ...vouchersWithVendorInfo,
      ];
    }, []);

    campaignVouchers = allVouchers.filter(voucher => voucher.campaign && (campaignIds.length === 0
      || campaignIds.includes((voucher.campaign).toString())));

    campaignVouchers = campaignVouchers.filter(voucher => moment().isBetween(voucher.campaignStartDate, voucher.campaignEndDate));

    if (maxSize > 0 && maxSize < campaignVouchers.length) {
      campaignVouchers = campaignVouchers.slice(0, maxSize);
    }
  }

  const handleVoucherClick = voucher => {
    setSelectedVoucher(voucher);
  };

  const isEmpty = !campaignIds;

  const skeletonVouchers = Array.from({ length: 2 }, (_, key) => <Voucher key={ key } />);

  return (
    <div className={ `hot-deals-voucher-container ${isEmpty ? 'empty skeleton-loader' : ''}` }>
      {
        <VouchersDisplay
          vouchers={
            (campaignVouchers || []).map(campaignVoucher => (
              <Voucher
                key={ campaignVoucher.id }
                iconUrl={ campaignVoucher.vendorIconUrl }
                onClick={ () => handleVoucherClick(campaignVoucher) }
                title={
                  <>
                    <div className='hotdeal-vendorName'>
                      {campaignVoucher.vendorName}
                    </div>
                    <div className='pay-get'>
                      {`Pay ${MoneyFormatter.format(MoneyFormatter.getValue(campaignVoucher.cost))} get `}
                      <em>
                        {MoneyFormatter.format(MoneyFormatter.getValue(campaignVoucher.options.buyNow.value))}
                      </em>
                    </div>
                  </>
                }
                addedValue={
                  `Get ${MoneyFormatter.format(MoneyFormatter.subtract(
                    MoneyFormatter.getValue(campaignVoucher.cost),
                    MoneyFormatter.getValue(campaignVoucher.options.buyNow.value),
                  ))} extra`
                }
                actionItem={ <AddToCartButtons voucher={ campaignVoucher } /> }
              />
            ))
          }
          isLoading={ !campaignVouchers }
          onLoading={ skeletonVouchers }
          onEmpty={ onEmpty }
        />
      }

      {
        selectedVoucher && (
          <Slider
            slot={
              <ProductVoucher
                voucher={ selectedVoucher }
                actionItem={ <AddToCartButtons voucher={ selectedVoucher } /> }
                descriptionType='buyNow'
              />
            }
            onClickOutside={ () => setSelectedVoucher(null) }
          />
        )
      }

      { cart.length > 0 && <CheckoutButton /> }
    </div>
  );
}

HotDealVouchers.propTypes = {
  vouchers: PropTypes.element,
  campaignIds: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  onEmpty: PropTypes.element,
};
