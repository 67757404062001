import React, { useContext, useEffect, useState } from 'react';
import CategoryIcons from '../../components/Categories/CategoryIcons/CategoryIcons.jsx';
import Advance from '../../components/Advance/Advance.jsx';
import CategoryCard from '../../components/Category/Card/CategoryCard.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { EligibilityContext } from '../../services/context/EligibilityContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { UserDetailsContext } from '../../services/context/UserDetailsContext.js';
import { withProfiler } from '@sentry/react';
import Carousel from '../../components/Carousel/Carousel.jsx';
import { PromosContext } from '../../services/context/PromosContext.js';
import LandingPagePopUp from '../../components/LandingPagePopUp/LandingPagePopUp.jsx';
import useApis from '../../services/hooks/useApis.js';
import { BearerTokenContext } from '../../services/context/BearerTokenContext.js';
import { SubscriptionDetailsContext, UpdateSubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { CampaignContext } from '../../services/context/CampaignContext.js';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { useNavigate } from 'react-router-dom';
import HotDealVouchers from '../../components/HotDealVouchers/HotDealVouchers.jsx';
import Panel from '../../components/Panel/Panel.jsx';

/**
 * @returns {JSX.Element} HomePage component
 */
function Home() {
  const userDetails = useContext(UserDetailsContext);
  const categories = useContext(CategoriesContext);
  const promos = useContext(PromosContext);
  const eligibility = useContext(EligibilityContext);
  const analyticsName = `${pagePrefix}: landing`;
  const bearerToken = useContext(BearerTokenContext);
  const { getBalances } = useApis();
  const subscriberType = localStorage.getItem('subscriberType');
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const setSubscriptionDetails = useContext(UpdateSubscriptionDetailsContext);
  const campaignIDs = useContext(CampaignContext);
  const { getSubscriptionPopUp } = useApis();
  const navigateTo = useNavigate();
  const [hideHotDeals, sethideHotDeals] = useState(true);

  const categoryHeaders = [
    name => `${name} brands for you`,
    name => `More ${name.toLowerCase()}, less money`,
    name => `All your ${name.toLowerCase()} needs`,
  ];

  const handleShowPopUpChange = value => {
    setIsShowPopUp(value);
    if (!value) {
      sessionStorage.setItem('isShowpopupClosed', 'true');
    }
  };

  // Get balances API integration
  const getBalanceForAirtime = async () => {
    if (subscriberType === 'PREPAID' || subscriberType === 'HYBRID') {
      try {
        const response = await getBalances(bearerToken); // Ensure msisdn is in scope or passed
        const totalAirtimeRemaining = response?.result?.serviceTypes?.find(service => service.name === 'AIRTIME')?.totalUnrestrictedRemaining || 'R 0.00';
        localStorage.setItem('Airtime-Remaining', totalAirtimeRemaining);
      } catch {
        localStorage.setItem('Airtime-Remaining', 'R 0.00');
      }
    }
  };

  useEffect(() => {
    if (userDetails.userId && userDetails.email && userDetails.msisdn) {
      const userInfo = {
        visitor_id_user: userDetails.userId,
        visitor_id_asset_active: userDetails.msisdn,
        visitor_id_marketing_email: userDetails.email,
      };
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [events.pageView],
        ...userInfo,
      });
    }

    getBalanceForAirtime();
  }, [userDetails]);

  useEffect(() => {
    const popupclosed = sessionStorage.getItem('isShowpopupClosed');
    if (!popupclosed && subscriptionDetails?.isShowPopUp) {
      setIsShowPopUp(true);
    }
  }, [subscriptionDetails]);

  useEffect(() => {
    const fetchData = async () => {
      if (bearerToken) {
        const response = await getSubscriptionPopUp(bearerToken);
        setSubscriptionDetails(response);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (hideHotDeals && !campaignIDs?.length) {
      sethideHotDeals(true);
    } else {
      sethideHotDeals(false);
    }
  }, [
    campaignIDs,
    hideHotDeals,
  ]);

  const loadedCategories = categories.filter(category => category.vendors.length > 0 && !category.isClubCategory);
  /** @type {JSX.Element[]} */
  const categoryCards = loadedCategories.map((category, index) => (
    <CategoryCard
      key={ category.id }
      category={ category }
      description={ categoryHeaders[index % categoryHeaders.length](category.name) }
    />
  ));

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <Carousel items={ promos } />
      <CategoryIcons />
      {
        eligibility?.tcl
        && <Advance amountAvailable={ eligibility.tcl } />
      }
      {
        userDetails?.vsp && campaignIDs?.length > 0 && !hideHotDeals && (
          <Panel
            panelHeaderText='Hot Deals for you'
            actionText='View all'
            onClick={ () => navigateTo(ROUTES.HOT_DEALS.get(campaignIDs)) }
          >
            <HotDealVouchers
              maxSize={ 2 }
              campaignIds={ campaignIDs }
            />
          </Panel>
        )
      }
      {
        categoryCards.length > 0 ? categoryCards
          : Array.from({ length: 4 }).map((_, index) => <CategoryCard key={ index } />)
      }
      {
        isShowPopUp && <LandingPagePopUp
          onShowPopupChange={ handleShowPopUpChange }
        />
      }
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(Home);
