import React from 'react';
import './HotDealsPage.css';
import TopBack from '../../components/TopBack/TopBack.jsx';
import HotDealsIcon from '../../assets/HotDealsIcon.jsx';
import HotDealVouchers from '../../components/HotDealVouchers/HotDealVouchers.jsx';
import { useParams, useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import Voucher from '../../components/Voucher/Voucher.jsx';

/**
 * @returns {JSX.Element} HotDealsPage
 */
export default function HotDealsPage() {
  const { id: idParam } = useParams();
  const navigateTo = useNavigate();
  const campaignIdsFromURL = idParam ? idParam.split(',') : [];

  const skeletonVouchers = Array.from({ length: 2 }, (_, key) => <Voucher key={ key } />);

  const NoDeals = () => {
    const emptySkeletonStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    };
    navigateTo(ROUTES.INVALID_HOTDEAL_PAGE);
    return (
      <div
        className='empty skeleton-loader'
        style={ emptySkeletonStyle }
      >
        { skeletonVouchers }
      </div>
    );
  };

  return (
    <div className='hot-deals-container'>
      <TopBack />
      <div className='hotDeal-header-container'>
        <HotDealsIcon />
        <span className='hotDeal-header'>Hot deals just for you</span>
      </div>
      <HotDealVouchers
        campaignIds={ campaignIdsFromURL }
        onEmpty={ <NoDeals /> }
      />
    </div>
  );
}
