import { createContext, Context, Dispatch, SetStateAction } from 'react';

/**
 * @type {Context<object>}
 */
export const CampaignContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<object>>>}
 */
export const UpdateCampaignContext = createContext(def => def);
